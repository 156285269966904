import React, { useState, useEffect, useRef, useId } from 'react';
import { styled } from '@stitches/react';
import { useDebounce } from 'Shared/Hooks/useDebounce';
import { useTranslationData } from 'Shared/Providers/TranslationProvider';
import { Control, UseFormRegister } from 'react-hook-form';
import LoadingCircle from 'DesignComponents/Atoms/Loaders/LoadingCircle';
import AnimatedIcon from 'DesignSystem/Icons/ExtenderIcons/AnimatedIcon';
import Label from 'DesignSystem/Typography/Label/Label';
import { FormInput } from 'DesignComponents/Atoms/Input/Input';
import { useController } from 'react-hook-form';

const API_KEY = process.env.REACT_APP_GEPOSIT_API_KEY;
const SE_ADDRESS_API_URL = 'https://app-api.geposit.se/v2.0/suggest/address/';
const DK_ADDRESS_API_URL =
  'https://api.dataforsyningen.dk/adresser/autocomplete';
const MIN_SEARCH_LENGTH = 3;

interface AutoSuggestAddressProps {
  name: string;
  countryCode: string;
  register: UseFormRegister<Record<string, any>>;
  error: boolean;
  errorText?: string;
  onAddressChange: (
    address: string,
    addressObject: SuggestionType,
    isSelectedFromList?: boolean
  ) => void;
  required?: boolean;
  control: Control<any>;
}

export type SuggestionType = {
  street: string;
  postcode: string;
  locality: string;
  street_number: string;
  letter: string;
  door: string;
  floor: string;
};

export type DanishSuggestionType = {
  adresse: {
    vejnavn: string;
    postnr: string;
    postnrnavn: string;
    husnr: string;
    dør: string;
    etage: string;
  };
  tekst: string;
};

function AutoSuggestAddress({
  name,
  countryCode,
  error = false,
  errorText,
  onAddressChange,
  required = true,
  control,
}: AutoSuggestAddressProps): JSX.Element {
  const addressInputId = useId();
  const { field } = useController({ name, control });
  const [currentCountryCode, setCurrentCountryCode] =
    useState<string>(countryCode);
  const [inputValue, setInputValue] = useState<string>(field.value);
  const [touched, setTouched] = useState(false);
  const [suggestions, setSuggestions] = useState<SuggestionType[]>([]);

  const [activeSuggestionIndex, setActiveSuggestionIndex] = useState(0);
  const [showSuggestions, setShowSuggestions] = useState(false);

  const [streetNumber, setStreetNumber] = useState<number | undefined>(
    undefined
  );
  const [getNew, setGetNew] = useState(true);

  const suggestionRefs = useRef<Array<HTMLLIElement | null>>([]);

  if (countryCode != currentCountryCode) {
    setCurrentCountryCode(countryCode);
    setSuggestions([]);
    setInputValue('');
    setTouched(false);
  }

  const {
    validationLabels: { optional },
    checkoutLabels: { address },
  } = useTranslationData();

  useEffect(() => {
    if (showSuggestions && activeSuggestionIndex >= 0) {
      const activeRef = suggestionRefs.current[activeSuggestionIndex];
      if (activeRef) {
        activeRef.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
        });
      }
    }
  }, [activeSuggestionIndex, showSuggestions]);

  const debouncedInputValue = useDebounce<string>(inputValue, 1000);

  // Debounce input changes
  useEffect(() => {
    if (debouncedInputValue && touched) {
      fetchSuggestions();
    }
  }, [debouncedInputValue]);

  function mapToSuggestionType(address: DanishSuggestionType): SuggestionType {
    return {
      street: address.adresse.vejnavn,
      street_number: address.adresse.husnr,
      letter: '',
      postcode: address.adresse.postnr,
      locality: address.adresse.postnrnavn,
      door: address.adresse.dør,
      floor: address.adresse.etage,
    };
  }

  const fetchSuggestions = async () => {
    if (!getNew) {
      return;
    }

    if (inputValue.length <= MIN_SEARCH_LENGTH) {
      return;
    }

    const streetNumberParam = streetNumber
      ? `&street_number=${streetNumber}`
      : '';

    try {
      switch (countryCode) {
        case 'se': {
          const seResponse = await fetch(
            `${SE_ADDRESS_API_URL}${countryCode}?api_key=${API_KEY}&query=${inputValue}${streetNumberParam}`
          );

          const swedishAddresses = await seResponse.json(); // Assuming the API returns an array of suggestions
          setSuggestions(swedishAddresses.suggestions);
          break;
        }

        case 'dk': {
          const dkResponse = await fetch(
            `${DK_ADDRESS_API_URL}?q=${inputValue}`
          );

          const danishAddresses = await dkResponse.json();
          const suggestions = danishAddresses.map(mapToSuggestionType);
          setSuggestions(suggestions);
          break;
        }
      }
    } catch (error) {
      console.error('Error fetching address suggestions', error); //eslint-disable-line
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;

    //get first number in string
    const streetNumber = parseInt(value);
    if (!isNaN(streetNumber)) {
      setStreetNumber(streetNumber);
    }

    setTouched(true);
    onAddressChange(value, {} as SuggestionType, false);
    setInputValue(value);
    field.onChange(value);
    setGetNew(true);
    if (value.length > 0) {
      setShowSuggestions(true);
      // Fetch suggestions
    } else {
      setShowSuggestions(false);
      setSuggestions([]);
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (!suggestions || suggestions.length === 0) return;
    setShowSuggestions(true);

    switch (event.key) {
      case 'ArrowDown':
        event.preventDefault();
        event.stopPropagation();
        setActiveSuggestionIndex((prevIndex) =>
          prevIndex < suggestions.length - 1 ? prevIndex + 1 : 0
        );
        break;
      case 'ArrowUp':
        setActiveSuggestionIndex((prevIndex) =>
          prevIndex > 0 ? prevIndex - 1 : prevIndex
        );
        break;
      case 'Enter':
        event.preventDefault();
        handleSuggestionClick(event, suggestions[activeSuggestionIndex]);
        break;
    }
  };

  const handleSuggestionClick = (
    event: React.MouseEvent | React.KeyboardEvent,
    suggestion: SuggestionType
  ) => {
    event.stopPropagation();
    const address = getAddressString(suggestion);
    setGetNew(false);

    let updatedAddress = '';

    if (suggestion?.postcode) {
      updatedAddress = address.replace(suggestion.postcode, '').trim();
      setInputValue(updatedAddress);
    }

    if (suggestion?.locality) {
      updatedAddress = updatedAddress.replace(suggestion.locality, '').trim();
      if (updatedAddress.endsWith(',')) {
        updatedAddress = updatedAddress.slice(0, -1).trim();
      }

      setInputValue(updatedAddress);
    }

    setShowSuggestions(false);
    onAddressChange(updatedAddress, suggestion, true);
  };

  const getAddressString = (suggestion: SuggestionType) => {
    //Danish address components are not always present
    const door = suggestion.door ? ', ' + suggestion.door : '';
    const floor = suggestion.floor ? ', ' + suggestion.floor : '';

    const letter = suggestion.letter ? suggestion.letter : '';
    const streetNumber = suggestion.street_number
      ? suggestion.street_number
      : '';

    return `${suggestion.street} ${streetNumber} ${letter}${floor}${door}, ${suggestion.postcode} ${suggestion.locality}`;
  };

  return (
    <div>
      <Label htmlFor={addressInputId}>
        {address} {!required && optional}
      </Label>
      <FormInput
        name={name}
        placeholder={address}
        type="text"
        id={addressInputId}
        value={inputValue}
        error={error}
        onChange={handleInputChange}
        onBlur={() => setShowSuggestions(false)}
        onKeyDown={handleKeyDown}
        aria-expanded={showSuggestions}
        aria-owns="suggestions-list"
        role="combobox"
        aria-autocomplete="list"
        aria-required={required}
        autoComplete={
          name == 'billing_fullAddress'
            ? 'billing street-address'
            : 'shipping street-address'
        }
        aria-activedescendant="suggestion-${activeSuggestionIndex}"
        maxLength={60}
      />
      {false && (
        <Loading>
          <AnimatedIcon animation={'spinAnimation'}>
            <LoadingCircle />
          </AnimatedIcon>
        </Loading>
      )}

      {showSuggestions && suggestions && suggestions.length > 0 && (
        <Container>
          <List id="suggestions-list" role="listbox">
            {suggestions.map((suggestion, index) => (
              <ListItem
                key={index}
                ref={(el) => (suggestionRefs.current[index] = el)}
                onMouseDown={(e) => handleSuggestionClick(e, suggestion)}
                selected={index === activeSuggestionIndex}
                aria-selected={index === activeSuggestionIndex}
                aria-posinset={index + 1}
                aria-setsize={suggestions.length}
                id={`suggestion-${index}`}
                role="option"
              >
                {getAddressString(suggestion)}
              </ListItem>
            ))}
          </List>
        </Container>
      )}
      {error && errorText && !showSuggestions && (
        <Label error>{errorText}</Label>
      )}
    </div>
  );
}

const Loading = styled('div', {
  display: 'flex',
  justifyContent: 'center',
});

const Container = styled('div', {
  position: 'relative',
});

const List = styled('ul', {
  backgroundColor: '$interactiveBackgroundDefault',
  padding: '10px',
  border: '1px solid $staticBorderDefault',
  position: 'absolute',
  top: '0',
  zIndex: '$DropDown',
  width: '100%',
  maxHeight: '212px',
  overflowY: 'auto',
  borderRadius: '5px',
});

const ListItem = styled('li', {
  padding: '10px',
  cursor: 'pointer',
  variants: {
    selected: {
      true: {
        backgroundColor: 'darkgray',
        color: 'white',
      },
    },
  },
});

export default AutoSuggestAddress;

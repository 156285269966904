import { styled } from 'Theme/stitches.config';
import Label from 'DesignSystem/Typography/Label/Label';
import { CSS } from '@stitches/react';

import React from 'react';

type PropType = {
  isChecked: boolean;
  onCheck?: React.ChangeEventHandler<HTMLInputElement>;
  text?: string;
  id?: string;
  css?: CSS;
  labelCss?: CSS;
  isDisabled?: boolean;
  error?: boolean;
  indeterminate?: boolean;
  noMargin?: true;
  groupName?: string;
  textBefore?: boolean;
  value?: string;
  button?: boolean;
  children?: () => JSX.Element;
};

function Checkbox({
  isChecked,
  onCheck,
  id,
  text,
  css,
  labelCss,
  isDisabled,
  error,
  indeterminate = false,
  noMargin,
  groupName,
  textBefore = false,
  value = text,
  button = false,
  children: Children,
}: PropType) {
  return (
    <CheckboxWrapper
      isChecked={isChecked}
      css={css}
      button={button}
      aria-selected={isChecked}
    >
      <Label
        interactive
        disabled={isDisabled}
        error={error}
        css={{ ...labelCss, fontSize: '$fontSize75' }}
      >
        {textBefore && text}
        <Input
          noMargin={noMargin}
          type="checkbox"
          id={id}
          indeterminate={isChecked && indeterminate ? true : false}
          isChecked={isChecked}
          checked={isChecked}
          onChange={onCheck}
          isDisabled={isDisabled}
          disabled={isDisabled}
          error={error}
          name={groupName}
          value={value}
        />
        {!textBefore && text}
        {Children && <Children />}
      </Label>
    </CheckboxWrapper>
  );
}

const CheckboxWrapper = styled('div', {
  variants: {
    button: {
      true: {
        borderRadius: '$space$s25',
        p: '$s100',
        border: '1px solid $onSurfaceDisabled',
      },
    },
    isChecked: {
      true: {
        borderWidth: 2,
        borderColor: '$onSurface',
      },
    },
  },
  compoundVariants: [
    {
      button: true,
      isChecked: true,
      css: {
        input: {
          borderColor: '$onSurface',
          '&:before': {
            color: '$onSurface',
          },
          '&:focus': {
            borderColor: '$onSurface',
          },
        },
      },
    },
  ],
});

const Input = styled('input', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'relative',
  flexShrink: 0,
  appearance: 'none',
  wh: 24,
  br: 1,
  mr: '$s50',
  borderWidth: 2,
  borderStyle: 'solid',
  cursor: 'pointer',
  borderColor: '$interactiveBorder',
  backgroundColor: '$surface',

  '&:focus-visible': {
    outline: 'solid 2px $focusBorder',
    boxShadow: 'none      ',
  },

  variants: {
    indeterminate: {
      true: {},
    },
    isChecked: {
      true: {
        borderColor: '$interactiveBorder',
        '&:focus': {
          borderWidth: 2,
          borderColor: '$interactiveBorderActive',
        },
        '&:before': {
          content: '',
          color: '$interactiveBorderActive',
          w: 14,
          h: 7,
          mb: '15%',
          position: 'absolute',
          borderBottom: 'solid 2px',
          borderLeft: 'solid 2px',
          transform: 'rotate(-40deg)',
        },
      },
    },
    error: {
      true: {
        borderColor: '$semanticError',
        borderWidth: 2,
      },
    },
    isDisabled: {
      true: {
        cursor: 'not-allowed',
        borderColor: '$interactiveDisabled_1',
      },
    },
    noMargin: {
      true: {
        mr: 0,
      },
    },
  },
  compoundVariants: [
    {
      isChecked: true,
      isDisabled: true,
      css: {
        borderColor: '$interactiveDisabled_1',
        backgroundColor: '$interactiveDisabled_1',
      },
    },
    {
      indeterminate: true,
      isDisabled: true,
      css: {
        borderColor: '$interactiveDisabled_1',
        backgroundColor: '$interactiveDisabled_1',
      },
    },
    {
      isChecked: true,
      error: true,
      css: {
        borderColor: '$semanticError',
        backgroundColor: '$semanticError',
      },
    },
    {
      isDisabled: true,
      error: true,
      css: {
        borderColor: '$interactiveDisabled_1',
      },
    },
    {
      indeterminate: true,
      error: true,
      css: {
        backgroundColor: '$semanticError',
        borderColor: '$semanticError',
      },
    },
    {
      indeterminate: true,
      isChecked: true,
      css: {
        '&:before': {
          content: '',
          color: '$onInteractivePrimary',
          h: 0,
          w: 8,
          mb: 0,
          borderLeft: 0,
          transform: 'none',
          position: 'absolute',
          borderBottom: 'solid 1px',
        },
      },
    },
  ],
});

export default Checkbox;

import { styled } from '@stitches/react';
import { mapModalView } from 'DesignComponents/Organisms/Modal/ModalContentViews';
import { mapModalFooterView } from 'DesignComponents/Organisms/Modal/ModalFooterViews';
import { ModalFooterLayoutOption } from 'Enums/ModalFooterLayout.enum';
import { API_ROOT_PATH } from 'Shared/Constants/route';
import { useTranslationData } from 'Shared/Providers/TranslationProvider';
import { useUiState } from 'Shared/Providers/UiState/UiStateProvider';
import TermsAndConditionsModalContainer from './TermsAndConditionsModalContainer';
import TermsAndConditionsModalFooter from './TermsAndConditionsModalFooter';
import TermsAndConditionsModel from 'Models/TermsAndConditions/TermsAndConditionsModel.interface';

export const GET_TERMSANDCONDITIONS_URL =
  API_ROOT_PATH + '/checkout/GetTermsAndConditions';

type TermsAndConditionsProps = {
  termsAndConditions?: TermsAndConditionsModel;
  type?: 'bookClub' | 'checkout';
};

function TermsAndConditions({
  termsAndConditions,
  type,
}: TermsAndConditionsProps) {
  const {
    checkoutLabels: {
      termsAndConditionsCtaText,
      termsAndConditionsBookClubText,
    },
  } = useTranslationData();

  const { modalState, toggleModal } = useUiState();

  const fetchData = async (
    callback: (data: { header: string; body: string }) => void
  ) => {
    if (termsAndConditions) {
      callback(termsAndConditions);
    } else {
      try {
        const response = await fetch(GET_TERMSANDCONDITIONS_URL, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });
        if (!response.ok) {
          throw new Error('Could not fetch data from resource');
        }
        const data = await response.json();

        callback(data);
      } catch (err) {
        console.error(err); //eslint-disable-line
      }
    }
  };

  const onTermsAndConditions = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();

    fetchData((data) => {
      toggleModal(
        !modalState.display,
        'TermsAndConditions',
        data?.header,
        {
          component: mapModalView<typeof TermsAndConditionsModalContainer>({
            type: 'TermsAndConditions',
            props: { text: data?.body },
          }),
        },
        mapModalFooterView<typeof TermsAndConditionsModalFooter>({
          type: 'TermsAndConditionsModalFooter',
          props: {
            displayModal: !modalState.display,
            modalFooterContent: {
              footerLayout: ModalFooterLayoutOption.SIDE_BY_SIDE,
            },
          },
        }),
        e && (e.currentTarget as HTMLElement)
      );
    });
  };

  return (
    <StyledTermsAndConditions onClick={onTermsAndConditions}>
      {type === 'bookClub'
        ? termsAndConditionsBookClubText
        : termsAndConditionsCtaText}
    </StyledTermsAndConditions>
  );
}

const StyledTermsAndConditions = styled('a', {
  color: '$emphasisedLinkColor',
  fontSize: '$fontSize75',
  fontFamily: 'fontMedium',
  cursor: 'pointer',
});

export default TermsAndConditions;

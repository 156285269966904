import { styled } from '../../../Theme/stitches.config';

export const SelectListOption = styled('option', {
  padding: '$s100',
});

export const SelectList = styled('select', {
  lineHeight: 'lh1125',
  fontSize: '$fontSizes$fontSize100',
  w: '100%',
  h: '48px',
  px: '$s100',
  py: '$s75',
  my: '$s25',
  borderRadius: '$radius$rExtraSmall',
  outline: '$borders$default solid $decorationEmphasized',
  backgroundColor: '$white',
  color: '$onSurface',

  '&::-webkit-inner-spin-button': {
    WebkitAppearance: 'none',
    margin: 0,
  },
  '&::placeholder': {
    color: '$onSurfaceDisabled',
  },
  '&:disabled': {
    background: '$surfaceVariant',
    cursor: 'not-allowed',
  },
  '&:focus-visible': {
    borderLeftWidth: '0px',
    outline: 'solid 2px $focusBorder',
    boxShadow: 'none',
    outlineOffset: '-2px',
  },

  variants: {
    error: {
      true: {
        borderLeft: '3px solid $semanticError',
        outline: '$borders$thick solid $semanticError',
        boxShadow: 'none',

        '&:focus-visible': {
          borderLeftWidth: '3px solid $semanticError',
          outline: '$borders$thick solid $semanticError',
          boxShadow: 'none',
        },
      },
    },
  },
});

import ContactDetailsModel from 'Models/Checkout/CustomerDetails/ContactDetailsModel.interface';
import ShippingAndBillingAddressModel from 'Models/Checkout/CustomerDetails/ShippingAndBillingAddressModel.interface';
import UserAgreementsModel from 'Models/Checkout/CustomerDetails/UserAgreementsModel.interface';
import CheckoutFormDataModel from 'Models/Checkout/CustomerDetails/CheckoutFormDataModel.interface';
import { AtLeastOne } from 'Shared/Types/types';
let abortController: AbortController = new AbortController();

export const UpdateCustomerDetails = async (
  contactDetailsModel: ContactDetailsModel,
  languageRoute: string
) => {
  return updateUserDetails({ contactDetailsModel }, languageRoute);
};

export const UpdateDeliveryDetails = async (
  shippingAndBillingAddressModel: ShippingAndBillingAddressModel,
  languageRoute: string
) => {
  return updateUserDetails({ shippingAndBillingAddressModel }, languageRoute);
};

export const UpdateUserAgreement = async (
  agreementsModel: UserAgreementsModel,
  languageRoute: string
) => {
  return updateUserDetails({ agreementsModel }, languageRoute);
};

export const updateUserDetails = async (
  object: AtLeastOne<CheckoutFormDataModel>,
  languageRoute: string
) => {
  abortController.abort();
  abortController = new AbortController();
  const { signal } = abortController;

  return await fetch(
    `/api/checkout/UpdateUserDetails?language=${languageRoute}`,
    {
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(object),
      signal,
    }
  );
};

export const GetPaymentStatus = async (orderGuid: string) => {
  abortController.abort();
  abortController = new AbortController();
  const { signal } = abortController;
  const response = await fetch(
    `/api/checkout/GetPaymentStatus?orderGuid=${orderGuid}`,
    {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
      signal,
    }
  );

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  return await response.json();
};

export const initializePaymentAsync = async (
  languageRoute: string,
  data: CheckoutFormDataModel
) => {
  abortController.abort();
  abortController = new AbortController();
  const { signal } = abortController;
  const response = await fetch(
    `/api/checkout/InitializePayment?language=${languageRoute}`,
    {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data),
      signal,
    }
  );

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  return await response.json();
};
